<template>
  <div>
      <b-row class="p-md-2">
          <b-col>
              <b-row >
                   <b-col cols="3">
                    
                <date-range-picker
                     style="display: inline;"
                      ref="picker"
                      opens="right"
                    v-model="dateRange"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      @update="init()"
                   ></date-range-picker>
                </b-col>
              </b-row>
          </b-col> 
    </b-row>
    <b-row>
      <b-col class="col-md-12">
        <b-card class="custom-modal-card-2">
          <b-row>
              <span class="custome-title col-md-12 m-1">Services</span>
          </b-row>
          <div class="col-md-12" v-if="dailyLoading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 

          <div v-else class="accordion" v-for="(service, index) in servicesList">
            <div no-body class="mb-2 custom-shadow">
              <b-card-header header-tag="header" role="tab" class="bg-white custom-font-22">
                  <div v-b-toggle="'accordion-' + index" variant="default" @click = toggleDetails(index) class="d-flex align-items-center">
                    <i class="fa fa-caret-right"></i>
                    <feather :type="currentItems[index] ?'chevron-down':'chevron-right'" class="mr-1"></feather>
                    {{ sentenceCase(service.provider_name) }} - {{ sentenceCase(service.sname) }} ({{ service.total }})
                  </div>
              </b-card-header>
              <b-collapse :id="'accordion-' + index" accordion="myaccordion" role="tabpanel">
                <b-card-body>
                  <div :v-if="currentItems[index] ?true:false">
                      <transcation-table :sid="service.sid" :edate="dateRange.endDate" :sdate="dateRange.startDate"></transcation-table>
                  </div>
                 
                </b-card-body>
              </b-collapse>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
// import LineChart from "@/components/Charts/LineChart";

import { BModal, VBModal, ToastPlugin, BvToast } from "bootstrap-vue";

// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

// Services
import dashbordService from "../../services/DashboardService";
import dailiesService from '../../services/DailyServices'
import ContentView from "./Dashboard/Content.vue";
import ServiceTable from "../views/Dashboard/ServicesTable.vue";
import TranscationTable from './../views/manager/Transcations/TranscationsTable.vue'
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
    TranscationTable,
    ToastPlugin,
    PageVisitsTable,
    SocialTrafficTable,
    ContentView,
    ServiceTable
  },
  data() {
    return {
      dailyLoading: true,
       dateRange:{
        startDate: new Date().setUTCHours(0, 0, 0, 0),
        endDate: new Date()
      },
      fields: ["_id",  "show_details"],
      items: [
        {
          isActive: true,
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
        },
        { isActive: false, age: 21, first_name: "Larsen", last_name: "Shaw" },
        {
          isActive: false,
          age: 89,
          first_name: "Geneva",
          last_name: "Wilson",
        },
        { isActive: true, age: 38, first_name: "Jami", last_name: "Carney" },
      ],
      thumbnailUri:
        process.env.VUE_APP_BACKHOST +
        ":" +
        process.env.VUE_APP_BACKPORT +
        "/videos/sr_",
      videoUrl: "",
      userActivateStatus: false,
      addetail: {},
      linkschain: [],
      allLinks: [],
      user: "",
      user_status: false,

currentItems: [],
      servicesList: [],
    };
  },
  
  methods: {
    async init() {
      let listPayload = {}
      listPayload.sdate = this.dateRange.startDate
      listPayload.edate = this.dateRange.endDate
      listPayload.country = localStorage.getItem('country')
      this.dailyLoading = true
      try {
          let listResponse =  await dailiesService.getlist(listPayload)
          if(listResponse.success) {
            this.servicesList = listResponse.data
          }
      } catch (e) {
        console.error("error in getting daily data");
        console.log(e);
      }
      this.dailyLoading = false
      

    },
    
   toggleDetails(index) {
    this.$set(this.currentItems, index, !this.currentItems[index]);
  // if (row.detailsShowing) {
  //   row.toggleDetails()
  // } else {
  //   this.currentItems.forEach(item => {
  //     this.$set(item, '_showDetails', false)
  //   })
  //   this.$nextTick(() => {
  //     row.toggleDetails()
  //   })
  // }
},
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;

      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },
    showModal(item) {
      this.addetail = item;
      this.videoUrl = `${process.env.VUE_APP_BACKHOST}:${process.env.VUE_APP_BACKPORT}/videos/sr_${item["_id"]}.mp4`;
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async getLinksData() {
      try {
        this.allLinks = await dashbordService.lists();
        console.log("all data", this.allLinks);
      } catch (e) {
        console.error("ERROR: getting data");
        console.error(e);
      }
    },
    sentenceCase(str) {
      if (typeof str !== 'string') return str
      return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    }
  },
  mounted() {
    this.init()
    this.userActivateStatus = window.sessionStorage.getItem("status");

    // if (window.sessionStorage.getItem("role") == "manager") {
    //   // this.$router.push("/dash");
    // } else {
    //   console.log("asds", window.sessionStorage.getItem("role"));
    //   if (
    //     !window.sessionStorage.getItem("role") ||
    //     window.sessionStorage.getItem("role") == "null"
    //   ) {
    //     this.$router.push("/login");
    //   } else {
    //     this.user = window.sessionStorage.getItem("username");
    //     this.user_status = window.sessionStorage.getItem("status");
    //     if (this.user_status == "true") {
    //     }
    //   }
    // }
  },
};
</script>
<style scoped>
.text-blue {
  color: #274AA6;
}
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}
/* .el-table .cell {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
} */

.custom-shadow{
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.18);
  border-radius: 10px !important;
}

.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
